<template>
  <div id="text-editor">
    <div ref="editor"></div>
    <slot></slot>
    <b-button class="mx-1" variant="primary" v-if="!this.hideSave" v-on:click="saveContent">저장</b-button>
  </div>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css'
import 'tui-color-picker/dist/tui-color-picker.css'
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css'
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css'

import Editor from '@toast-ui/editor'
import colorSyntax from '@toast-ui/editor-plugin-color-syntax'
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight'
import FileAPI from '@/api/fileAPI'

export default {
  components: {},
  props: ['propsData', 'hideSave', 'height', 'boardData', 'preventImage', 'maxWidth'],
  data() {
    return {
      contents: '',
      imageAPI: new FileAPI('images'),
      imageMap: {},
      htmlParser: new DOMParser(),
      editorWrapper: null
    }
  },
  setup() {},
  created() {
    if (this.propsData) {
      this.contents = this.propsData
    }
  },
  watch: {
    propsData() {
      this.editor.setHTML(this.propsData)
    }
  },
  async mounted() {
    if (this.maxWidth) this.$refs.editor.parentElement.style.maxWidth = this.maxWidth

    const toolbar = []
    toolbar.push('table')
    if (!this.preventImage) toolbar.push('image')
    toolbar.push('link')
    // INFO: vue 스타일로 components로 불러오기 시도 했을때 에러나서 이 방법으로
    this.editor = new Editor({
      el: this.$refs.editor,
      plugins: [colorSyntax, codeSyntaxHighlight],
      initialEditType: 'wysiwyg', // 초기 모드
      useCommandShortcut: false, // 키보드 입력 컨트롤 방지? 숏컷키 안쓰기?
      usageStatistics: false, // false 하면됨
      hideModeSwitch: true, // 모드변경 button 안보이게 하기
      minHeight: '200px',
      height: this.height ?? '200px',
      toolbarItems: [
        // 툴바 사용 설정, []로 구분하면 구분자가 생긴다
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task', 'indent', 'outdent'],
        toolbar,
        ['code', 'codeblock', 'scrollSync']
      ],
      initialValue: this.contents ?? '', // 초기 text 설정
      hooks: {
        addImageBlobHook: (blob, callback) => {
          if (this.preventImage) return alert('이미지는 넣을 수 없습니다.')

          this.addImageBlobHookCallback(blob, callback)
        }
      }
    })

    // editor안의 img src 재 설정
    await this.replaceImgSrc()
  },
  methods: {
    /**
     * 에디터에 이미지 업로드 시 실행
     * @param {File} blob 업로드된 파일
     * @param {Function} callback
     */
    addImageBlobHookCallback(blob, callback) {
      // 이미지 크기 제한
      const imageSize = blob.size / 1024 ** 2
      if (imageSize > 2) return alert('2MB이하의 이미지만 사용할 수 있습니다.')

      // 업로드 되는 이미지 임시 저장
      const imageName = blob.name
      this.imageMap[imageName] = blob

      // 이미지 미리보기
      const uploadImageUrl = URL.createObjectURL(blob)

      // img태그의 src, alt 적용
      callback(uploadImageUrl, imageName)
    },

    /**
     * 에디터의 img태그의 src 변경
     */
    async replaceImgSrc() {
      // 에디터 view Element select
      const editorWrapper = this.$refs.editor.querySelector('.toastui-editor-ww-container')

      // img 태그 선택
      const imageList = editorWrapper.querySelectorAll('img:not(.ProseMirror-separator)')

      for (const image of imageList) {
        // download api를 이용하여 미리보기 url 생성
        const src = await this.imageAPI.createObjectURL(image.alt, this.boardData.boardName, this.boardData.boardId)
        image.src = src
      }

      this.imageMap = this.imageAPI._blobMap
    },

    async saveImages(boardData) {
      // 서버에 저장 되어있는 image 전체삭제
      await this.imageAPI.deleteAll(boardData)

      // string html => DOM
      const html = this.editor.getHTML()
      const parser = this.htmlParser
      const HTML = parser.parseFromString(html, 'text/html').body

      // DOM => image 선택
      const imageList = HTML.querySelectorAll('img:not(.ProseMirror-separator)')

      // image의 alt속성에 저장된 filename으로 실제로 서버에 저장할 image찾기
      for (const img of imageList) {
        const imageName = img.alt

        const blob = this.imageMap[imageName]
        await this.imageAPI.add(blob)

        img.src = imageName
      }

      // 서버에 image 저장
      await this.imageAPI.send(boardData)
      return HTML.innerHTML
    },

    async saveContent() {
      // 부모 컴포넌트의 저장 기능 호출
      this.$emit('emitData', { getHtmlAfterSaveImages: this.saveImages, editor: this.editor })
    }
  },
  unmounted() {}
}
</script>

<style scoped>
#text-editor {
  max-width: 1028px;
  /* margin: 0 auto; */
}
</style>
